import { makeStyles, Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scrollable: {
      marginTop: '-10px',
      padding: theme.spacing(1),
      maxWidth: '600px',
      maxHeight: '200px',
      overflowY: 'auto',
      alignItems: 'center',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',

      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.grey[300],
        borderRadius: '2px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.grey[500],
        borderRadius: '2px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.primary.dark,
      },
    },
  }),
);
