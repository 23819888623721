import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { Tab, useMediaQuery, ListItem, ListItemText, IconButton, Grid } from '@material-ui/core';
import Button from '../../../../../Shared/Button/Button';
import Container from '../../../../../Shared/Container/Container';
import { Fieldset } from '../../../../../Shared/Form/Fieldset/Fieldset';
import { InputField } from '../../../../../Shared/InputFields/InputField/InputField';
import Snackbar from '../../../../../Shared/Hooks/UseErrorMessage/Snackbar/Snackbar';
import Tabs from '../../../../../Shared/Tabs/Tabs';
import AccessGuard from '../../../../../Core/Authentication/AccessGuard/AccessGuard';
import { ModuleIdentifiers, AccessRights } from '../../../../../Core/Authentication/ModuleAccess';
import SystemLogList from '../../../Admin/Components/SystemLog/SystemLogList/SystemLogList';
import { SystemLogType } from '../../../Admin/Components/SystemLog/SystemLog.api';
import ContactList from '../Contacts/ContactList/ContactList';
import {
  useGetCustomer,
  useUpdateCustomerCreditCheck,
  creditCheckOutcomeText,
  autoCreditCheckIntervals,
  useDeleteCustomer,
} from '../../Customers.api';
import FacilityList from '../Facilities/FacilityList/FacilityList';
import { IFacility, useGetFacility } from '../Facilities/Facilities.api';
import CoreSystemTable from '../Facilities/ViewFacility/CoreSystemTable/CoreSystemTable';
import DocumentList from './Documents/Components/DocumentList/DocumentList';
import { isErrorResponse } from '../../../../../Shared/Api/response/IErrorRespose';
import formatDate from '../../../../../Shared/Formatting/formatDate';
import useErrorMessage from '../../../../../Shared/Hooks/UseErrorMessage/useErrorMessage';
import theme from '../../../../../Styles/Theme';
import { FacilityChangeCustomerButton } from '../Facilities/FacilityChangeCustomerButton/FacilityChangeCustomerButton';
import AgreementList from './AgreementList/AgreementList';
import { InputFieldLoading } from '../../../../../Shared/InputFields/InputField/InputFieldLoading/InputFieldLoading';
import { useGetDocument } from './Documents/Components/Documents.api';
import DownloadButton from '../../../../../Shared/DownloadButton/DownloadButton';
import { useStyles } from '../../../../../Shared/Table/TableRowHeader/TableRowHeader.styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { SaleList } from './SaleList/SaleList';
import WarningModal from '../../../../../Shared/WarningModal/WarningModal';
import { ViewAddress } from '../../../../../Shared/InputFields/Address/ViewAddress';
import PopperMenu from '../../../../../Shared/Popper/Popper';
import OrderForm from '../../../Errands/Components/Order/OrderForm/OrderForm';
import { customerHasDocumentId } from '../CreditWorthyControll/Common';

interface IDeleteWarning {
  customerNumber: number;
  customerName: string;
  numberOfFacilities?: number;
}

const ViewCustomer = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { path } = useRouteMatch();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { isLoading, customer, getCustomer } = useGetCustomer();
  const { isLoading: isFacilityLoading, getFacility } = useGetFacility(id);
  const { isLoading: isCreditCheckLoading, makeCreditCheck } = useUpdateCustomerCreditCheck(id);
  const { setErrorMessage } = useErrorMessage();
  const { isLoading: isGettingDocument, getDocument } = useGetDocument(id);
  const [openOrderPopup, setOrderPopup] = useState<boolean>(false);

  const [facility, setFacility] = useState<IFacility>();
  const [openConfirmText, setOpenConfirmText] = useState(false);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const showAddFacilityButton = !customer?.facilities || customer.facilities.length <= 1;
  const history = useHistory();
  const facilityId = useMemo(() => facility?.id.toString(), [facility]);
  const { isDeleteLoading, deleteCustomer } = useDeleteCustomer();
  const [confirmDeleteCustomerId, setConfirmDeleteCustomerId] = useState<number>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [hasFacility, setHasFacility] = useState<boolean>(false);
  const [includeArchived, setincludeArchived] = useState<boolean>(false);
  const [modalWarningMessage, setModalWarningMessage] = useState<IDeleteWarning>();

  const loadCustomer = useCallback(async () => {
    const response = await getCustomer(`${id}/${includeArchived}`);

    if (!response) {
      history.push('/customers');
    } else {
      if (response.facilities.length === 1) {
        setFacility(await getFacility(response.facilities[0].id.toString()));
      } else {
        setFacility(undefined);
      }
    }
  }, [getCustomer, getFacility, history, id, includeArchived]);

  useEffect(() => {
    loadCustomer();
  }, [loadCustomer]);

  function setOpenPopup() {
    setOrderPopup(!openOrderPopup);
  }

  async function handleMakeNewCreditCheck() {
    if (!customer) return;
    const result = await makeCreditCheck({
      identificationNumber: customer.identificationNumber,
      type: customer.type,
    });
    if (isErrorResponse(result)) {
      setErrorMessage({ message: 'TODO: what do I put here?' });
    } else {
      loadCustomer();
    }
  }

  const handleDeleteCustomer = useCallback(async () => {
    if (confirmDeleteCustomerId) {
      await deleteCustomer(confirmDeleteCustomerId);
    }

    setConfirmDeleteCustomerId(undefined);
    setOpenModal(false);
    setHasFacility(false);
    history.push('/customers');
  }, [confirmDeleteCustomerId, history, deleteCustomer]);

  const handleConfirmRemoveCustomer = useCallback(() => {
    if (customer) {
      let warningMessage: IDeleteWarning | undefined;
      setConfirmDeleteCustomerId(customer.id);

      if (customer?.facilities?.length > 0) {
        setHasFacility(true);
        warningMessage = {
          customerNumber: customer.customerNumber,
          customerName: customer.name,
          numberOfFacilities: customer.facilities.length,
        };
      } else {
        warningMessage = {
          customerNumber: customer.customerNumber,
          customerName: customer.name,
        };
      }

      setOpenModal(true);
      setModalWarningMessage(warningMessage);
    }
  }, [customer]);

  return (
    <>
      <Container
        customSize={{ lg: 6, md: 12 }}
        loading={isLoading || isFacilityLoading}
        label={customer?.name}
        mobileView={mobileView || smallScreen}
        form
        actions={
          <AccessGuard module={ModuleIdentifiers.Customer} accessRights={AccessRights.Write}>
            {mobileView || smallScreen ? (
              <>
                <div className={classes.mobileButtonList}>
                  <ListItem button onClick={() => setShowButtons(!showButtons)}>
                    <ListItemText primary={'Åtgärder'} />
                    <IconButton />
                    {showButtons ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  <div className={classes.mobileButton}>
                    {showButtons && (
                      <>
                        <span className={classes.mobileButtonBorder}></span>
                        <Button
                          color="primary"
                          variant="outlined"
                          loading={isCreditCheckLoading}
                          disabled={isCreditCheckLoading}
                          onClick={handleMakeNewCreditCheck}
                        >
                          Ny upplysning
                        </Button>
                        {showAddFacilityButton && (
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() =>
                              history.push(`${history.location.pathname}/facility/create?redirect-to-customer=1`)
                            }
                          >
                            Lägg till plats
                          </Button>
                        )}
                        {customer && (
                          <Button
                            role="btnRemove"
                            color="default"
                            variant="outlined"
                            onClick={handleConfirmRemoveCustomer}
                          >
                            Ta bort
                          </Button>
                        )}
                        {customer && customer.facilities.length === 1 && (
                          <>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={() =>
                                history.push(`${history.location.pathname}/facility/${customer?.facilities[0].id}`)
                              }
                            >
                              Visa plats
                            </Button>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={() =>
                                history.push(
                                  `${history.location.pathname}/facility/${customer?.facilities[0].id}/update?redirect-to-customer=1`,
                                )
                              }
                            >
                              Redigera plats
                            </Button>
                            {facilityId && (
                              <FacilityChangeCustomerButton
                                onUpdateCustomer={loadCustomer}
                                currentCustomerId={id}
                                facilityId={facilityId}
                              />
                            )}
                          </>
                        )}
                        <Button
                          color="default"
                          variant="outlined"
                          onClick={() => history.push(`${history.location.pathname}/update`)}
                        >
                          Redigera kund
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  loading={isCreditCheckLoading}
                  disabled={isCreditCheckLoading}
                  onClick={handleMakeNewCreditCheck}
                >
                  Ny upplysning
                </Button>
                {showAddFacilityButton && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => history.push(`${history.location.pathname}/facility/create?redirect-to-customer=1`)}
                  >
                    Lägg till plats
                  </Button>
                )}
                {customer && customer.facilities.length === 1 && (
                  <>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        history.push(`${history.location.pathname}/facility/${customer?.facilities[0].id}`)
                      }
                    >
                      Visa plats
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        history.push(
                          `${history.location.pathname}/facility/${customer?.facilities[0].id}/update?redirect-to-customer=1`,
                        )
                      }
                    >
                      Redigera plats
                    </Button>
                    {facilityId && (
                      <FacilityChangeCustomerButton
                        onUpdateCustomer={loadCustomer}
                        currentCustomerId={id}
                        facilityId={facilityId}
                      />
                    )}
                  </>
                )}
                {customer && (
                  <Button color="default" variant="outlined" onClick={handleConfirmRemoveCustomer}>
                    Ta bort
                  </Button>
                )}
                <Button
                  color="default"
                  variant="outlined"
                  onClick={() => history.push(`${history.location.pathname}/update`)}
                >
                  Redigera
                </Button>
                <Button
                  color="default"
                  variant="outlined"
                  onClick={setOpenPopup}
                  disabled={facility?.coreSystems.length === 0}
                  title={facility?.coreSystems.length === 0 ? 'Platsen saknar system.' : undefined}
                >
                  Skapa serviceorder
                </Button>
              </>
            )}
          </AccessGuard>
        }
      >
        <Fieldset
          legend="Kundens uppgifter"
          customSize={{ lg: 12, md: 12 }}
          contentAlignment="flex-start"
          useAsExpandPanel
          expandedAsDefault={true}
        >
          <InputField
            fullwidth={mobileView}
            customWidth={4}
            label="Kundnummer"
            value={customer?.customerNumber.toString()}
            viewOnly
          />
          <InputField
            fullwidth={mobileView}
            customWidth={4}
            label="Avtalsfakturanummer"
            value={customer?.agreementInvoiceNumber}
            viewOnly
          />
          <InputField
            fullwidth={mobileView}
            customWidth={4}
            label="Org-/Pers.nr"
            value={customer?.identificationNumber}
            viewOnly
          />
          <InputField fullwidth={mobileView} customWidth={4} label="Namn" value={customer?.name} viewOnly />
          <InputField
            fullwidth={mobileView}
            customWidth={4}
            label="Telefon"
            value={customer?.customerPhoneNumber}
            viewOnly
          />
          <InputField fullwidth={mobileView} customWidth={4} label="E-post" value={customer?.customerEmail} viewOnly />
          <InputField
            fullwidth={mobileView}
            customWidth={4}
            label="Gatuadress"
            value={customer?.customerAddress}
            viewOnly
          />
          <InputField
            fullwidth={mobileView}
            customWidth={4}
            label="Postnummer"
            value={customer?.customerPostalCode}
            viewOnly
          />
          <InputField fullwidth={mobileView} customWidth={4} label="Ort" value={customer?.customerCity} viewOnly />
        </Fieldset>
        <Fieldset legend="Fakturauppgifter" customSize={{ lg: 12, md: 12 }} contentAlignment="flex-start">
          <InputField
            fullwidth={mobileView}
            customWidth={4}
            label="Fakturaadress"
            value={customer?.invoiceAddress}
            viewOnly
          />
          <InputField
            fullwidth={mobileView}
            customWidth={4}
            label="Postnummer"
            value={customer?.invoicePostalCode}
            viewOnly
          />
          <InputField fullwidth={mobileView} customWidth={4} label="Ort" value={customer?.invoiceCity} viewOnly />
          <InputField fullwidth={mobileView} customWidth={4} label="E-post" value={customer?.invoiceEmail} viewOnly />
          <InputField
            fullwidth={mobileView}
            customWidth={12}
            label="Faktureringsreferens"
            value={customer?.invoiceReference}
            viewOnly
          />
        </Fieldset>
        {(isLoading || isFacilityLoading || facility) && (
          <Fieldset legend="Platsuppgifter" contentAlignment="flex-start">
            <InputField label={'Platsnamn'} value={facility?.name} viewOnly customWidth={4} fullwidth={mobileView} />
            <InputField
              label={'Kundnummer'}
              value={facility?.facilityNumber}
              viewOnly
              customWidth={4}
              fullwidth={mobileView}
            />
            <ViewAddress
              address={facility?.address}
              postalCode={facility?.postalCode}
              city={facility?.city}
              customWidth={4}
            />
            <InputField
              label={'E-faktura'}
              value={customer?.invoiceEmail}
              viewOnly
              customWidth={4}
              fullwidth={mobileView}
            />
            <InputField
              label={'Avtalsfakturanr'}
              value={
                facility?.coreSystems.find((x) => x.activeAgreement?.isActive)?.activeAgreement
                  .externalAgreementInvoiceNumber
              }
              viewOnly
              customWidth={4}
              fullwidth={mobileView}
            />
            <ViewAddress
              customLabel="Fakturaadress"
              address={facility?.invoiceAddress}
              postalCode={facility?.invoicePostalCode}
              city={facility?.invoiceCity}
              customWidth={4}
            />
            <InputField
              label={'E-faktura anteckning'}
              value={facility?.invoiceNote}
              viewOnly
              customWidth={4}
              fullwidth={mobileView}
            />
            <InputField
              label={'Anteckningar'}
              value={facility?.internalNote}
              customWidth={4}
              multiline
              viewOnly
              fullwidth={mobileView}
            />
          </Fieldset>
        )}
        <Fieldset legend="Upplysning" customSize={{ lg: 12, md: 12 }} contentAlignment="flex-start">
          {isCreditCheckLoading ? (
            <InputFieldLoading />
          ) : (
            <>
              <InputField
                label="Senaste kontroll"
                value={customer?.creditCheck ? formatDate(customer.creditCheck.date) : 'Ingen kontroll har utförts'}
                viewOnly
                customWidth={3}
              />

              <InputField
                label="Bedömning"
                value={
                  creditCheckOutcomeText.find((x) => x.value === customer?.creditCheck?.status)?.label ||
                  'Ingen kontroll har utförts'
                }
                viewOnly
                customWidth={3}
              />
              <InputField
                label="Automatisk kontroll"
                value={
                  autoCreditCheckIntervals.find((x) => x.option === customer?.autoCreditCheckInterval)?.text ||
                  'Ej vald'
                }
                viewOnly
                customWidth={3}
              />
              {customerHasDocumentId(customer?.creditCheck) && (
                <DownloadButton
                  customWidth={3}
                  loading={isGettingDocument}
                  onClick={() => getDocument(customer?.creditCheck?.documentId.toString() ?? undefined)}
                >
                  Visa upplysning
                </DownloadButton>
              )}
            </>
          )}
        </Fieldset>
        <WarningModal
          id={confirmDeleteCustomerId!}
          title="Observera!"
          open={openModal}
          isLoading={isDeleteLoading}
          setOpen={setOpenModal}
          action={handleDeleteCustomer}
        >
          <>
            <Grid container item>
              {hasFacility ? (
                <>
                  {`Du försöker ta bort kund ${modalWarningMessage?.customerName}, som har följande kopplingar.`}
                  {modalWarningMessage && (
                    <ul>
                      {modalWarningMessage.numberOfFacilities && (
                        <li>{`${modalWarningMessage.numberOfFacilities} platser.`}</li>
                      )}
                    </ul>
                  )}
                </>
              ) : (
                <>{`Du försöker ta bort kund ${modalWarningMessage?.customerName}`}</>
              )}
            </Grid>

            <Grid container item>
              {hasFacility
                ? `
              Är du säker på att du vill ta bort kunden? Allt listat ovan kommer att tas bort tillsammans med all
              information kopplad till den här kunden. Kopplade avtal kommer att sägas upp med omedelbar verkan. Detta
              går inte att ändra senare.`
                : `Är du säker på att du vill ta bort kunden? `}
            </Grid>
          </>
        </WarningModal>
      </Container>
      <Tabs
        customSize={{
          lg: 6,
          md: 12,
        }}
      >
        <Tab label="Kontakter">
          <ContactList customerId={id} showFacility mobileView={mobileView} />
          <Snackbar
            message={'Ny inbjudan har skickats.'}
            open={openConfirmText}
            severity={'success'}
            onClose={() => setOpenConfirmText(false)}
          />
        </Tab>
        <Tab label="Ordrar">
          <SaleList customerId={id} mobileView={mobileView} />
        </Tab>
        <Tab label="Avtal">
          <AgreementList customerId={id} />
        </Tab>
        <Tab label="Dokument">
          <DocumentList customerName={customer?.name} customerId={id} />
        </Tab>
        <Tab label="Händelser">
          {customer && (
            <SystemLogList
              customSize={{ fullsize: true }}
              defaultEntityId={customer?.id}
              lockCustomerId
              defaultLogType={SystemLogType.Customer}
              lockLogType
              hideLabel
              customPage={[10, 15, 20]}
            />
          )}
        </Tab>
      </Tabs>
      {customer && customer.facilities.length > 1 && !facility && (
        <FacilityList
          includeArchived={includeArchived}
          setincludeArchived={setincludeArchived}
          facilities={customer?.facilities}
          customerId={customer?.id}
          loading={isLoading}
          reloadCustomer={loadCustomer}
        />
      )}
      {(isFacilityLoading || facility) && (
        <CoreSystemTable
          coreSystems={facility?.coreSystems}
          loading={isFacilityLoading}
          currentPath={`${history.location.pathname}/facility/${facility?.id}`}
        />
      )}
      {openOrderPopup && (
        <PopperMenu
          setOpen={setOpenPopup}
          open={openOrderPopup}
          onClose={() => setOpenPopup()}
          buttonType="icon"
          customContent
          alwaysDialog
          title={'Lägg till serviceorder'}
          content={
            <OrderForm
              location={`errands/orders`}
              fullwidth
              customerId={customer?.id.toString()}
              facilityId={facility?.id.toString()}
            />
          }
        />
      )}
    </>
  );
};

export default ViewCustomer;
