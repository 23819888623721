import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Link from '../../../../../../../Shared/Link/Link';
import Container from '../../../../../../../Shared/Container/Container';
import WizardFormActions from '../../../../../../../Shared/Form/WizardFormActions/WizardFormActions';
import DownloadButton from '../../../../../../../Shared/DownloadButton/DownloadButton';
import Button from '../../../../../../../Shared/Button/Button';
import { Fieldset } from '../../../../../../../Shared/Form/Fieldset/Fieldset';
import { ViewField } from '../../../../../../../Shared/InputFields/InputField/ViewField';
import { InputField } from '../../../../../../../Shared/InputFields/InputField/InputField';
import { getCustomerTypeName } from '../../../../../../../Shared/Misc/CustomerType';
import { isResultError } from '../../../../../../../Shared/Api/response/ICreateResult';
import formatDate from '../../../../../../../Shared/Formatting/formatDate';
import formatPrice from '../../../../../../../Shared/Formatting/formatPrice';
import { SaleType, SaleTypeLabel } from '../../../../../../Shared/SaleType';
import { creditCheckOutcomeText } from '../../../../../Customers/Customers.api';
import { useGetDocument } from '../../../../../Customers/Components/ViewCustomer/Documents/Components/Documents.api';
import {
  auditIntervals,
  measures,
  rentTypes,
  SaleStatus,
  services,
  transfersIntervals,
  transferTypes,
} from '../../../Sale.api';
import OfferFormContext from '../OfferFormContext';
import {
  IPublishOfferDraftCommand,
  getPreviousPage,
  useGetOfferSummary,
  usePublishOfferDraft,
  useGetOffer,
  useNotifyCustomer,
} from '../../Offer.api';
import useStyles from '../../ViewOffer/OfferSummary/OfferSummary.styles';
import { AccessRights, ModuleIdentifiers } from '../../../../../../../Core/Authentication/ModuleAccess';
import { Action } from '../../../../../Admin/Components/SystemLog/SystemLog.api';
import { customerHasDocumentId } from '../../../../../Customers/Components/CreditWorthyControll/Common';

const Summary: React.FunctionComponent = () => {
  const { id: offerId } = useParams<{ id: string }>();
  const { offerStep, setOfferStep, offerType, identifier } = useContext(OfferFormContext);
  const { offer, getOffer } = useGetOffer();
  const { notifyCustomer } = useNotifyCustomer();
  const history = useHistory();
  const classes = useStyles();

  const { response: summary, get: getOfferSummary } = useGetOfferSummary(offerId);
  const { isLoading: isGettingDocument, getDocument } = useGetDocument(summary?.customer.id.toString());
  const { put: publishOfferDraft, isLoading: isCreateNewOfferLoading } = usePublishOfferDraft(offerId);

  useEffect(() => {
    getOfferSummary();
  }, [getOfferSummary]);

  const upsertNewOffer = async (sendToCustomer: boolean) => {
    if (offerType === undefined || !offerId) {
      return;
    }

    const publishOfferDraftCommand: IPublishOfferDraftCommand = {
      sendToCustomer: sendToCustomer,
    };

    const result = await publishOfferDraft(publishOfferDraftCommand);

    if (!isResultError(result)) {
      if (sendToCustomer) {
        notifyCallback();
      }
      history.push('/errands/offers/' + offerId);
    } else {
      // TODO: Set error message?
    }
  };

  const notifyCallback = useCallback(async () => {
    setTimeout(async () => {
      await notifyCustomer({ saleId: parseInt(offerId), currentAction: Action.CreateOffer });
    }, 200);
  }, [offerId, notifyCustomer]);

  useEffect(() => {
    if (offerId) {
      getOffer(offerId);
    }
  }, [offerId, getOffer]);

  const viewOfferCallback = useCallback(
    (sendOfferToCustomer: boolean) => {
      if (sendOfferToCustomer) {
        notifyCallback();
      }
      history.push('/errands/offers/' + offerId);
    },
    [history, offerId, notifyCallback],
  );

  /* var sendToCustomer = useCallback(async () => {
    if (offerId) {
      await getOffer(offerId);
      viewOfferCallback(true);
    }
  }, [offerId, getOffer, viewOfferCallback]); */

  return (
    <>
      <Container
        form
        label={'Granska ' + identifier}
        loading={isCreateNewOfferLoading}
        actions={
          <>
            <WizardFormActions goBack={() => setOfferStep(getPreviousPage(offerType!, offerStep))} />
            {offer?.status === SaleStatus.new || offer?.status === SaleStatus.pending_approval ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => viewOfferCallback(false)}
                loading={isCreateNewOfferLoading}
              >
                Spara
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => upsertNewOffer(false)}
                loading={isCreateNewOfferLoading}
              >
                Slutför
              </Button>
            )}
            {/* offer?.status === SaleStatus.new || offer?.status === SaleStatus.pending_approval ? (
              <Button variant="contained" color="primary" onClick={sendToCustomer} loading={isCreateNewOfferLoading}>
                Spara och skicka offert
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => upsertNewOffer(true)}
                loading={isCreateNewOfferLoading}
              >
                Slutför och skicka offert
              </Button>
            )*/}
          </>
        }
        customSize={{ md: 12, lg: 6 }}
        alignment="flex-start"
      >
        {summary && (
          <>
            <Fieldset legend="Kund" customSize={{ md: 6, lg: 6 }}>
              <ViewField label="Kund">
                <Link
                  to={`/customers/${summary.customer.id}`}
                  module={ModuleIdentifiers.Customer}
                  accessRights={AccessRights.Read}
                >
                  {summary.customer.name}
                </Link>
              </ViewField>

              <InputField label="Person/Org nr" type="text" value={summary.customer.identificationNumber} viewOnly />
              <InputField label="Kundtyp" type="text" value={getCustomerTypeName(summary.customer.type)} viewOnly />
              <InputField
                label="Senaste kontroll"
                value={
                  summary.customer.creditCheck
                    ? formatDate(summary.customer.creditCheck.date)
                    : 'Ingen kontroll har utförts'
                }
                viewOnly
              />
              <InputField
                label="Bedömning"
                value={
                  creditCheckOutcomeText.find((x) => x.value === summary.customer.creditCheck?.status)?.label ||
                  'Ingen kontroll har utförts'
                }
                viewOnly
              />
              {customerHasDocumentId(summary?.customer?.creditCheck) && (
                <DownloadButton
                  customWidth={6}
                  loading={isGettingDocument}
                  onClick={() => getDocument(summary.customer.creditCheck?.documentId.toString() ?? undefined)}
                >
                  Visa upplysning
                </DownloadButton>
              )}
            </Fieldset>
            {summary.customer.contactPerson && (
              <Fieldset legend="Kontakt" customSize={{ md: 6, lg: 6 }}>
                <InputField label="Namn" type="text" value={summary.customer.contactPerson.name} viewOnly />
                <InputField label="E-post" type="text" value={summary.customer.contactPerson.email} viewOnly />
                <InputField label="Telefon" type="text" value={summary.customer.contactPerson.phoneNumber} viewOnly />
                <InputField
                  label="Befattning/Avdelning"
                  type="text"
                  value={summary.customer.contactPerson.position}
                  viewOnly
                />
              </Fieldset>
            )}
            <Fieldset legend="Allmänt" customSize={{ md: 6, lg: 6 }}>
              <InputField label="Offerttyp" type="text" value={SaleTypeLabel[offerType!]} viewOnly />
              <InputField label="Projektledare" type="text" value={summary.projectLeader} viewOnly />
              <InputField label="Säljare" type="text" value={summary.salesPerson} viewOnly />
              <InputField label="Projektör" type="text" value={summary.projector} viewOnly />
              <InputField fullwidth label="Offert beskrivning" type="text" value={summary.description} viewOnly />
            </Fieldset>
            <Grid xs item>
              <div className={classes.forPdf}>
                <ViewField
                  label={'Förklarande text på offert'}
                  value={summary.offerDescription}
                  multiline
                  scrollable
                  fullwidth={true}
                />
              </div>
            </Grid>
            <Fieldset alignment="flex-start">
              {offerType === SaleType.system && (
                <Fieldset legend="System" customSize={{ md: 12, lg: 12 }}>
                  {summary.system && offer != null && offer.coreSystem && (
                    <InputField label="Komplement till system" type="text" value={summary.system.name} viewOnly />
                  )}
                  <InputField label="Systemtyp" type="text" value={summary.system?.systemType} viewOnly />
                  <InputField label="Regelverk" type="text" value={summary.system?.regulation} viewOnly />
                </Fieldset>
              )}
              {summary.agreement && (
                <Fieldset legend="Avtal" customSize={{ md: 12, lg: 12 }}>
                  <InputField
                    label="Månadsavgift"
                    type="text"
                    value={formatPrice(summary.agreement.monthlyCost)}
                    viewOnly
                  />
                  <InputField label="Datum från" type="text" value={formatDate(summary.agreement.startDate)} viewOnly />
                  <InputField
                    label="Datum till"
                    type="text"
                    value={summary.agreement.endDate ? formatDate(summary.agreement.endDate) : 'Tillsvidare'}
                    viewOnly
                  />
                  {summary.agreement.emergencyCallCenter && (
                    <InputField
                      label="Larmcentral"
                      type="text"
                      value={summary.agreement.emergencyCallCenter}
                      viewOnly
                    />
                  )}
                  {summary.agreement.securityCompany && (
                    <InputField label="Vaktbolag" type="text" value={summary.agreement.securityCompany} viewOnly />
                  )}
                  {summary.agreement.measure && (
                    <InputField
                      label="Åtgärd"
                      type="text"
                      value={measures.find((x) => x.type === summary.agreement?.measure)?.name}
                      viewOnly
                    />
                  )}
                  {summary.agreement.service && (
                    <InputField
                      label="Service"
                      type="text"
                      value={services.find((x) => x.type === summary.agreement?.service)?.name}
                      viewOnly
                    />
                  )}
                  {summary.agreement.auditInterval && (
                    <InputField
                      label="Revision"
                      type="text"
                      value={auditIntervals.find((x) => x.type === summary.agreement?.auditInterval)?.name}
                      viewOnly
                    />
                  )}

                  {summary.agreement.rentType && (
                    <InputField
                      label="Hyra"
                      type="text"
                      value={rentTypes.find((x) => x.type === summary.agreement?.rentType)?.name}
                      viewOnly
                    />
                  )}
                  {summary.agreement.transferType && (
                    <InputField
                      label="Överföring"
                      type="text"
                      value={transferTypes.find((x) => x.type === summary.agreement?.transferType)?.name}
                      viewOnly
                    />
                  )}
                  {summary.agreement.secondaryTransferType && (
                    <InputField
                      label="Sekundär överföring"
                      type="text"
                      value={transferTypes.find((x) => x.type === summary.agreement?.secondaryTransferType)?.name}
                      viewOnly
                    />
                  )}
                  {summary.agreement.transferInterval && (
                    <InputField
                      label="Överföringsperiod"
                      type="text"
                      value={transfersIntervals.find((x) => x.type === summary.agreement?.transferInterval)?.name}
                      viewOnly
                    />
                  )}
                  <InputField
                    label="Nyckelförvaring: Inklusive inre kontroll"
                    type="text"
                    value={summary.agreement.includeInnerControl ? 'Ja' : 'Nej'}
                    viewOnly
                  />
                </Fieldset>
              )}

              {summary.terms && (
                <Fieldset legend="Villkor" customSize={{ md: 12, lg: 12 }}>
                  <InputField
                    label="Inklusive"
                    type="text"
                    multiline
                    rows="5"
                    value={summary.terms.including}
                    viewOnly
                  />
                  <InputField
                    label="Enligt regelverk"
                    multiline
                    rows="5"
                    type="text"
                    value={summary.terms.regulation}
                    viewOnly
                  />
                  <InputField
                    label="Exklusive"
                    multiline
                    rows="5"
                    type="text"
                    value={summary.terms.excluding}
                    viewOnly
                  />
                  <InputField label="Giltighetstid" type="text" value={summary.terms.validPeriod} viewOnly />
                  <InputField label="Leverans" multiline rows="5" type="text" value={summary.terms.delivery} viewOnly />
                  <InputField label="Garanti" multiline rows="5" type="text" value={summary.terms.warranty} viewOnly />
                  <InputField
                    label="Kan godkännas direkt av kund"
                    fullwidth
                    type="text"
                    value={summary.canBeApprovedByCustomer ? 'Ja' : 'Nej'}
                    viewOnly
                  />
                </Fieldset>
              )}
            </Fieldset>
          </>
        )}
      </Container>
    </>
  );
};

export default Summary;
